import React from "react"
import Promoover from "../nav/Promoover"

interface PromoOverRedirectProps {
	children: any
}

function dateToUnixTimestamp(dateString: string): number {
	const date = new Date(dateString)
	// Eine Stunde in Millisekunden abziehen
	const offsetInMilliseconds = 60 * 60 * 1000
	const adjustedDate = new Date(date.getTime() - offsetInMilliseconds)
	return Math.floor(adjustedDate.getTime() / 1000)
}

const PromoOverHandler: React.FC<PromoOverRedirectProps> = ({ children }) => {
	const isPromoOver = parseInt(Math.floor(Date.now() / 1000).toString()) > dateToUnixTimestamp("2024-12-01T00:00:00Z")

	return <>{isPromoOver ? <Promoover /> : <>{children}</>}</>
}

export default PromoOverHandler
